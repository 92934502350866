// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-layouts-posts-page-layout-js": () => import("./../../../src/components/layouts/posts-page-layout.js" /* webpackChunkName: "component---src-components-layouts-posts-page-layout-js" */),
  "component---src-components-layouts-shows-page-layout-js": () => import("./../../../src/components/layouts/shows-page-layout.js" /* webpackChunkName: "component---src-components-layouts-shows-page-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ajanlatkeres-js": () => import("./../../../src/pages/ajanlatkeres.js" /* webpackChunkName: "component---src-pages-ajanlatkeres-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koszonjuk-js": () => import("./../../../src/pages/koszonjuk.js" /* webpackChunkName: "component---src-pages-koszonjuk-js" */),
  "component---src-pages-sportbemutatok-js": () => import("./../../../src/pages/sportbemutatok.js" /* webpackChunkName: "component---src-pages-sportbemutatok-js" */)
}

