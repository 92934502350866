/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("animate.css")
require("react-bnb-gallery/dist/style.css")
require("yet-another-react-lightbox/dist/styles.css");
require("./src/components/layouts/layout.css")
const wow = require("wowjs")
const SmoothScroll = require("smooth-scroll")

exports.onClientEntry = () => {
  new SmoothScroll('a[href*="#"]', {
    easing: "easeOutQuad",
  })
}

exports.onRouteUpdate = () => {
  new wow.WOW({ live: false, mobile: false }).init()
}
